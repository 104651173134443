import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Layout, ListingPagination, SimpleImage, Spinner } from '@ten24/slatwallreactlibrary/components'
import { useGetEntityWithPagination, useUtilities } from '@ten24/slatwallreactlibrary/hooks'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

const Manufacturer = () => {
  let { eventHandlerForWSIWYG } = useUtilities()
  const history = useHistory()
  let loc = useLocation()
  const content = useSelector(state => state.content[loc.pathname.substring(1)])
  const { title, customBody } = content || {}
  const { maxCount } = useSelector(state => state.configuration.shopByManufacturer)
  let { currentPage = 1 } = queryString.parse(loc.search, { arrayFormat: 'separator', arrayFormatSeparator: ',' })
  let { isFetching, records, totalRecords, totalPages } = useGetEntityWithPagination('brand', currentPage, maxCount, 'brandName|asc', '{"includeImages":true}')

  return (
    <Layout>
      <div className="p-0">
        <div className="page-title-overlap bg-lightgray pt-4">
          <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
            <div className="order-lg-1 pr-lg-4 text-center">
              <h1 className="h3 text-dark mb-0 font-accent">{title || ''}</h1>
            </div>
          </div>
        </div>
        <div className="container bg-light box-shadow-lg rounded-lg p-5">
          <div
            className="content-body"
            onClick={eventHandlerForWSIWYG}
            dangerouslySetInnerHTML={{
              __html: customBody || '',
            }}
          />
          {customBody}
          <div className="pb-4 pb-sm-5">
            {isFetching && <Spinner />}

            <div className="row">
              {!isFetching &&
                records.length &&
                records.map(brand => {
                  return (
                    <div key={brand.brandID} className="d-flex col-6 col-sm-4 col-md-3 col-lg-2 mb-4">
                      <Link className="card border-1 shadow-sm text-center d-flex flex-column rounded-lg hover-shadow-none" to={`/search?facet_brandUrlTitle=${brand.urlTitle}`}>
                        <div className="d-flex align-items-center flex-1 p-3 vendor-child-list">{brand && !!brand.images && <SimpleImage className="d-block w-100 p-2" src={brand.images[0]} alt={brand.brandName} style={{ maxHeight: '150px', maxWidth: '190px', background: '#FFF' }} type="brand" />} </div>
                        <h2 className="h6 mx-1">{brand.brandName}</h2>
                      </Link>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className="container">
            <ListingPagination
              recordsCount={totalRecords}
              currentPage={currentPage}
              totalPages={totalPages}
              setPage={pageNumber => {
                history.push({
                  pathname: loc.pathname,
                  search: `?currentPage=${pageNumber}`,
                })
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Manufacturer
