import Slider from 'react-slick'
import { useGetProductsByEntity } from '../../hooks/useAPI'
import { ProductCard } from '../ProductCard/ProductCard'

const ProductSlider = ({ children, params = {}, settings, title, slidesToShow = 4 }) => {
  let { isFetching, records } = useGetProductsByEntity(params)
  settings = settings
    ? settings
    : {
        dots: false,
        infinite: records && records.length >= slidesToShow,
        // infinite: true,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      }
  const products = records && records.map(product => {
    return { ...product, brandName: product.brand_brandName, brandUrlTitle: product.brand_urlTitle, imageFile: product.defaultSku_imageFile, skuCode: product.defaultSku_skuCode, product: product.defaultSku_imageFile, skuID: product.defaultSku_skuID }
  })
  return (
    <div className="container">
      <div className="featured-products shadow bg-white text-center my-5 py-3">
        <h3 className="h3 mb-0">{title}</h3>
        {children}
        {!isFetching && <Slider style={{ margin: '0 2rem', height: 'fit-content' }} className="row mt-4" {...settings}>
          {products?.map(product => {
            return <ProductCard {...product} key={product.defaultSku_skuID} />
          })}
        </Slider>}
      </div>
    </div>
  )
}
export { ProductSlider }
